import {
  Box,
  Paper,
  Typography,
  Grid,
  Button,
  makeStyles,
  Tooltip
} from '@material-ui/core'
import { Plus, Trash, Edit2, Eye } from 'react-feather'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { components, useServices } from 'cng-web-lib'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import NmotTranslationText from 'src/views/nmot/shared/NmotTranslationText'
import NMoTApiUrls from 'src/apiUrls/NMoTApiUrls.js'
import SelectedRowsDialog from './SelectedRowsDialog.js'
import AllRowsDialog from './AllRowsDialog.js'
import DisclaimerPromptDialog from 'src/views/nmot/shared/component/DisclaimerPromptDialog'
import CngBackdrop from 'src/views/vesselschedule/searchschedule/cngcomponent/CngBackDrop'
import pathMap from 'src/paths/PathMap_Nmot'

const {
  table: { CngCrudTable, useDefaultNotification, DateRangeFilter },
  button: { CngPrimaryButton },
  CngDialog
} = components

const useStyles = makeStyles((theme) => ({
    div: {
        overflow: 'hidden',
        '& .MuiTable-root': {
            width: '150%',
            '& .MuiTableHead-root .MuiTableRow-head .MuiTableCell-head': {
                borderBottom: 'none',
                resize: 'horizontal',
                overflowX: 'hidden',
                overflowY: 'hidden',
                fontWeight: 'bold',
                '&:nth-child(1)': {
                    width: '3% !important'
                },
            },
            '& .MuiTableRow-root[index]': {
                '&:nth-child(even)': {
                    backgroundColor: '#fafbfc'
                },
                '& td[class*="MuiTableCell-root"]': {
                    border: '1px solid #f1f4f9 !important',
                    borderLeft: 'none !important',
                    borderRight: 'none !important',
                    '&:nth-child(1)': {
                        width: '3% !important'
                    },
                    '&:nth-child(2)': {
                        width: '8% !important'
                    },
                    '&:nth-child(3)': {
                        width: '5% !important'
                    },
                    '&:nth-child(4)': {
                        width: '6% !important'
                    },
                    '&:nth-child(5):nth-child(6)': {
                        width: '5% !important'
                    },
                    '&:nth-child(7)': {
                        width: '6% !important'
                    },
                    '&:nth-child(8)': {
                        width: '6% !important'
                    },
                    '&:nth-child(9):nth-child(11)': {
                        width: '4% !important'
                    },
                    '&:nth-child(12)': {
                        width: '5% !important'
                    },
                    '&:nth-child(13)': {
                        width: '5% !important'
                    },
                    '&:nth-child(14)': {
                        width: '7% !important'
                    },
                    '&:nth-child(n + 15):nth-last-child(n + 2)': {
                        width: '5% !important'
                    },
                    '&:nth-last-child(1)': {
                        width: '6% !important'
                    }
                }
            },
            '& .MuiTableRow-footer': {
                width: '65%'
            }
        }
    },
    trashIcon: {
        color: theme.palette.text.error,
        width: theme.spacing(2),
        height: theme.spacing(2)
    },
    editViewIcon: {
        color: theme.palette.primary.main,
        width: theme.spacing(2),
        height: theme.spacing(2)
    },
    addIcon: {
        color: theme.palette.primary.main,
        width: theme.spacing(3),
        height: theme.spacing(3)
    }
}))

function DashboardTable(props) {
    const translatedTextsObject = NmotTranslationText()
    const { fetchRecords, securedSendRequest, createRecord } = useServices()
    const classes = useStyles()
    const { showNotification } = props

    const notification = useDefaultNotification(showNotification)

    const tableRef = useRef()
    const cngTableRef = useRef()
    const darkRef = useRef('')
    const [loading, setLoading] = useState(true)
    const selectedRowData = useRef([])
    const [firstRecordsCall, setFirstRecordsCall] = useState(false)
    const [waitTime, setWaitTime] = useState(0)
    const [completedPercentage, setCompletedPercentage] = useState(null)
    const [lastRefreshedDT, setLastRefreshedDT] = useState('')
    const [refreshCount, setRefreshCount] = useState(0)
    const [isUpdating, setIsUpdating] = useState(false)
    const [noData, setNoData] = useState(false)
    const [columns, setColumns] = useState([])

    const history = useHistory()

    const color = {
        501: 'gray',
        502: 'green',
        503: 'red'
    }


    function setSelectedRowData() {
        selectedRowData.current = []
    }

    const status = {
        401: 'Announced',
        402: 'On Vessel',
        403: 'On Yard',
        404: 'Exit Terminal',
        405: 'No Right'
    }

    const colorStatus = {
        502: 'YES',
        503: 'NO'
    }

    const tableRefStateCallback = useCallback(() => {
        return tableRef.current?.state
    }, [])

    function renderDateColumn(date) {
        if (!date) {
            return null
        }

        return moment(date).format('DD/MM/YYYY HH:mm:ss')
    }

    function renderRefreshDate(date) {
        if (!date) {
            return null
        }

        return moment(date).format('DD MMM YYYY hh:mm A')
    }

    function loadColumns(hdList) {
        const columns = [
            {
                field: 'contNo',
                customSort: (a, b) => {
                    const contNo1 = a.contNo ? a.contNo.toLowerCase() : ''
                    const contNo2 = b.contNo ? b.contNo.toLowerCase() : ''
                    return contNo1.localeCompare(contNo2) > 0 ? 1 : -1
                },
                title: (
                    <Tooltip title={translatedTextsObject.contNo}>
                        <span>{translatedTextsObject.contNo}</span>
                    </Tooltip>
                ),
                show: true,
                render: (rowData) => {
                    return (
                        <Tooltip title={rowData.contNo}>
                            <Box
                                style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}
                            >
                                {rowData.contNo}
                            </Box>
                        </Tooltip>
                    )
                }
            },
            {
                field: 'nmot',
                customSort: (a, b) => {
                    const nmot1 = a.nmot ? a.nmot.toLowerCase() : ''
                    const nmot2 = b.nmot ? b.nmot.toLowerCase() : ''
                    return nmot1.localeCompare(nmot2) > 0 ? 1 : -1
                },
                title: (
                    <Tooltip title={translatedTextsObject.nmot}>
                        <span>{translatedTextsObject.nmot}</span>
                    </Tooltip>
                ),
                show: true,
                render: (rowData) => {
                    return (
                        <Tooltip title={rowData.nmot}>
                            <Box>
                                <img
                                    width='40'
                                    height='40'
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/static/images/nmot/' +
                                        rowData.nmot.toLowerCase() +
                                        '.svg'
                                    }
                                />
                            </Box>
                        </Tooltip>
                    )
                },
                lookup: { BARGE: 'Barge', RAIL: 'Rail', TRUCK: 'Truck' }
            },
            {
                field: 'loCode',
                customSort: (a, b) => {
                    const loCode1 = a.loCode && hdList[a.loCode] ? hdList[a.loCode].toLowerCase() : ''
                    const loCode2 = b.loCode && hdList[b.loCode] ? hdList[b.loCode].toLowerCase() : ''
                    return loCode1.localeCompare(loCode2) > 0 ? 1 : -1
                },
                title: (
                    <Tooltip title={translatedTextsObject.hinterlandDest}>
                        <span>{translatedTextsObject.hinterlandDest}</span>
                    </Tooltip>
                ),
                show: true,
                render: (rowData) => {
                    return (
                        <Tooltip title={hdList[rowData.loCode]}>
                            <Box
                                style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}
                            >
                                {hdList[rowData.loCode]}
                            </Box>
                        </Tooltip>
                    )
                },
                customFilterAndSearch: (term, rowData) => {
                    return rowData.loCode && term && hdList[rowData.loCode]
                        && hdList[rowData.loCode].toLowerCase().includes(term.trim().toLowerCase())
                }
            },
            {
                field: 'nextCarrier',
                title: (
                    <Tooltip title={translatedTextsObject.nextCarrier}>
                        <span>{translatedTextsObject.nextCarrier}</span>
                    </Tooltip>
                ),
                show: true,
                render: (rowData) => {
                    return (
                        <Tooltip title={rowData.nextCarrier}>
                            <Box
                                style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}
                            >
                                {rowData.nextCarrier}
                            </Box>
                        </Tooltip>
                    )
                }
            },
            {
                field: 'blNo',
                title: (
                    <Tooltip title={translatedTextsObject.blNo}>
                        <span>{translatedTextsObject.blNo}</span>
                    </Tooltip>
                ),
                show: true,
                render: (rowData) => {
                    return (
                        <Tooltip title={rowData.blNo}>
                            <Box
                                style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}
                            >
                                {rowData.blNo}
                            </Box>
                        </Tooltip>
                    )
                }
            },
            {
                field: 'goodDesc',
                title: (
                    <Tooltip title={translatedTextsObject.goodDesc}>
                        <span>{translatedTextsObject.goodDesc}</span>
                    </Tooltip>
                ),
                show: true,
                render: (rowData) => {
                    return (
                        <Tooltip title={rowData.goodDesc}>
                            <Box
                                style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}
                            >
                                {rowData.goodDesc}
                            </Box>
                        </Tooltip>
                    )
                }
            },
            {
                field: 'pickupTime',
                title: (
                    <Tooltip title={translatedTextsObject.pickupTime}>
                        <span>{translatedTextsObject.pickupTime}</span>
                    </Tooltip>
                ),
                show: true,
                render: (rowData) => {
                    let date = renderDateColumn(rowData.pickupTime)
                    return (
                        <Tooltip title={date}>
                            <Box
                                style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}
                            >
                                {date}
                            </Box>
                        </Tooltip>
                    )
                },
                type: 'date',
                filterComponent: DateRangeFilter
            },
            {
                field: 'status',
                title: (
                    <Tooltip title={translatedTextsObject.status}>
                        <span>{translatedTextsObject.status}</span>
                    </Tooltip>
                ),
                show: true,
                render: (rowData) => {
                    return (
                        <Tooltip title={status[rowData.status]}>
                            <Box
                                style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}
                            >
                                {status[rowData.status]}
                            </Box>
                        </Tooltip>
                    )
                },
                lookup: status
            },
            {
                field: 'dispatchTime',
                title: (
                    <Tooltip title={translatedTextsObject.eToDdesc}>
                        <span>{translatedTextsObject.eToD}</span>
                    </Tooltip>
                ),
                show: true,
                render: (rowData) => {
                    let date = renderDateColumn(rowData.dispatchTime)
                    return (
                        <Tooltip title={date}>
                            <Box
                                style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}
                            >
                                {date}
                            </Box>
                        </Tooltip>
                    )
                },
                type: 'date',
                filterComponent: DateRangeFilter
            },
            {
                field: 'gateOut',
                title: (
                    <Tooltip title={translatedTextsObject.gateOut}>
                        <span>{translatedTextsObject.gateOut}</span>
                    </Tooltip>
                ),
                show: true,
                render: (rowData) => {
                    let date = renderDateColumn(rowData.gateOut)
                    return (
                        <Tooltip title={date}>
                            <Box
                                style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}
                            >
                                {date}
                            </Box>
                        </Tooltip>
                    )
                },
                type: 'date',
                filterComponent: DateRangeFilter
            },
            {
                field: 'overall',
                title: (
                    <Tooltip title={translatedTextsObject.overall}>
                        <span>{translatedTextsObject.overall}</span>
                    </Tooltip>
                ),
                show: true,
                render: (rowData) => {
                    let checkNull = rowData.overall != null && rowData.overall != 500
                    return (
                        <Box>
                            {checkNull ? (
                                <img
                                    width='20'
                                    height='20'
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/static/images/nmot/' +
                                        color[rowData.overall] +
                                        '.svg'
                                    }
                                />
                            ) : null}{' '}
                        </Box>
                    )
                },
                lookup: colorStatus
            },
            {
                field: 'onYardTerminal',
                title: (
                    <Tooltip title={translatedTextsObject.onYardTerminal}>
                        <span>{translatedTextsObject.onYardTerminal}</span>
                    </Tooltip>
                ),
                show: true,
                render: (rowData) => {
                    let checkNull =
                        rowData.onYardTerminal != null && rowData.onYardTerminal != 500
                    return (
                        <Box>
                            {checkNull ? (
                                <img
                                    width='20'
                                    height='20'
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/static/images/nmot/' +
                                        color[rowData.onYardTerminal] +
                                        '.svg'
                                    }
                                />
                            ) : null}{' '}
                        </Box>
                    )
                },
                lookup: colorStatus
            },
            {
                field: 'commercialRelease',
                title: (
                    <Tooltip title={translatedTextsObject.commercialRelease}>
                        <span>{translatedTextsObject.commercialRelease}</span>
                    </Tooltip>
                ),
                show: true,
                render: (rowData) => {
                    let checkNull =
                        rowData.commercialRelease != null && rowData.commercialRelease != 500
                    return (
                        <Box>
                            {checkNull ? (
                                <img
                                    width='20'
                                    height='20'
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/static/images/nmot/' +
                                        color[rowData.commercialRelease] +
                                        '.svg'
                                    }
                                />
                            ) : null}{' '}
                        </Box>
                    )
                },
                lookup: colorStatus
            },
            {
                field: 'customsRelease',
                title: (
                    <Tooltip title={translatedTextsObject.customsRelease}>
                        <span>{translatedTextsObject.customsRelease}</span>
                    </Tooltip>
                ),
                show: true,
                render: (rowData) => {
                    let checkNull =
                        rowData.customsRelease != null && rowData.customsRelease != 500
                    return (
                        <Box>
                            {checkNull ? (
                                <img
                                    width='20'
                                    height='20'
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/static/images/nmot/' +
                                        color[rowData.customsRelease] +
                                        '.svg'
                                    }
                                />
                            ) : null}{' '}
                        </Box>
                    )
                },
                lookup: colorStatus
            },
            {
                field: 'bot',
                title: (
                    <Tooltip title={translatedTextsObject.notBot}>
                        <span>{translatedTextsObject.notBot}</span>
                    </Tooltip>
                ),
                show: true,
                render: (rowData) => {
                    let checkNull = rowData.bot != null && rowData.bot != 500
                    return (
                        <Box>
                            {checkNull ? (
                                <img
                                    width='20'
                                    height='20'
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/static/images/nmot/' +
                                        color[rowData.bot] +
                                        '.svg'
                                    }
                                />
                            ) : null}{' '}
                        </Box>
                    )
                },
                lookup: colorStatus
            },
            {
                field: 'createdDate',
                title: (
                    <Tooltip title={translatedTextsObject.uploadedCreatedDate}>
                        <span>{translatedTextsObject.uploadedCreatedDate}</span>
                    </Tooltip>
                ),
                show: true,
                defaultSort: 'desc',
                render: (rowData) => {
                    let date = renderDateColumn(rowData.createdDate)
                    return (
                        <Tooltip title={date}>
                            <Box
                                style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}
                            >
                                {date}
                            </Box>
                        </Tooltip>
                    )
                },
                type: 'date',
                filterComponent: DateRangeFilter
            }
        ]
        setColumns(columns)
        // setLoading(false)
    }

    useEffect(() => {
        function onLoadThemeSuccess() {
            fetchRecords.execute(
                NMoTApiUrls.H_ACTIVE_DEST_GET,
                undefined,
                (data) => {
                    loadHdList(data).then((temp) => {
                        loadColumns(temp)
                        setLoading(false)
                    }).catch(() => {
                        setLoading(false)
                    })
                },
                (error) => {
                    console.log(error)
                    setLoading(false)
                }
            )
        }

        securedSendRequest.execute(
            'GET',
            `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/user-preference/by-current-user`,
            {},
            (data) => {
                darkRef.current = data.data.theme
                onLoadThemeSuccess()
            },
            (error) => {
                console.log(error)
                setLoading(false)
            }
        )
    }, [])

    useEffect(() => {
        // For refreshing table when page is loaded (only does it after the table is loaded with the NMoT data currently existing in the db)
        if (firstRecordsCall) {
            refreshTable(false)
        }
    }, [firstRecordsCall])

    const loadHdList = async (data) => {
        return new Promise((resolve, reject) => {
            try {
                let temp = {}
                data.content.forEach((ctry) => {
                    temp[ctry.loCode] = ctry.place
                })
                resolve(temp)
            } catch (error) {
                console.log(error)
                reject()
            }
        })
    }

    const editNMoT = (data) => {
        history.push({
            pathname: pathMap.NMOT_EDIT,
            state: { nmot: data, isEdit: true }
        })
    }

    const viewNMoT = (data) => {
        history.push({
            pathname: pathMap.NMOT_VIEW,
            state: { nmot: data, isView: true }
        })
    }

    const addNMoT = () => {
        history.push({
            pathname: pathMap.NMOT_ADD
        })
    }

    function refreshTable(refreshClicked) {
        setIsUpdating(true)
        createRecord.execute(
            NMoTApiUrls.NMOT_DASHBOARD_REFRESH,
            {},
            (data) => {
                if (data.status == 'NO_DATA') {
                    setIsUpdating(false)
                    setNoData(true)
                } else if (data.status == 'TOO_MANY_REQ' && refreshClicked) {
                    setIsUpdating(false)
                    setWaitTime(data.waitFor)
                    setFreqRefreshDialogOpen(true)
                } else if (data.status == 'TOO_MANY_REQ') {
                    setIsUpdating(false)
                    setWaitTime(data.waitFor)
                } else if (data.status == 'RUNNING') {
                    setCompletedPercentage(data.completedPercentage) // should be 0
                    setRefreshCount(1)
                } else if (data.status == 'COMPLETED') {
                    setCompletedPercentage(null)
                    setRefreshCount(0)
                    setLastRefreshedDT(data.lastRefreshedDt)
                    setIsUpdating(false)
                    if (cngTableRef.current && cngTableRef.current.performRefresh) {
                        cngTableRef.current.performRefresh()
                        setLastRefreshedDT(data.lastRefreshedDt)
                    }
                } else if (data.status == 'ERROR') {
                    setRefreshCount(0)
                    setRefreshErrorDialogOpen(true)
                    setIsUpdating(false)
                }
            },
            (error) => {
                console.log(error)
                setRefreshCount(refreshCount + 1)
            }
        )
    }

    useEffect(() => {
        if (refreshCount !== 0) {
            setTimeout(() => {
                createRecord.execute(
                    NMoTApiUrls.NMOT_DASHBOARD_REFRESH,
                    {},
                    (data) => {
                        if (data.status == 'COMPLETED') {
                            setCompletedPercentage(null)
                            setRefreshCount(0)
                            setLastRefreshedDT(data.lastRefreshedDt)
                            setIsUpdating(false)
                            if (cngTableRef.current && cngTableRef.current.performRefresh) {
                                cngTableRef.current.performRefresh()
                                setLastRefreshedDT(data.lastRefreshedDt)
                            }
                        } else if (data.status == 'ERROR') {
                            setRefreshCount(0)
                            setRefreshErrorDialogOpen(true)
                            setIsUpdating(false)
                        } else {
                            setRefreshCount(refreshCount + 1)
                            setCompletedPercentage(data.completedPercentage)
                        }
                    },
                    (error) => {
                        console.log('refresh error', error)
                        setRefreshCount(refreshCount + 1)
                    }
                )
            }, 1000)
        }
    }, [refreshCount])

    const [isSelectedDialogOpen, setSelectedDialogOpen] = useState(false)

    function closeSelectedDialog() {
        setSelectedDialogOpen(false)
    }

    const [isAllDialogOpen, setAllDialogOpen] = useState(false)

    function closeAllDialog() {
        setAllDialogOpen(false)
    }

    const [isFreqRefreshDialogOpen, setFreqRefreshDialogOpen] = useState(false)

    function closeFreqRefreshDialog() {
        setFreqRefreshDialogOpen(false)
    }

    const [isRefreshErrorDialogOpen, setRefreshErrorDialogOpen] = useState(false)

    function closeRefreshErrorDialog() {
        setRefreshErrorDialogOpen(false)
    }

    return (
        <Paper>
            {!loading ? (
                <Box
                    m='8px'
                    className={darkRef.current.trim() === 'LIGHT' ? classes.div : ''}
                >
                    {firstRecordsCall && !noData && (
                        <Box pt='8px' pl='8px'>
                            <Typography variant='body2' style={{ fontWeight: 600 }}>
                                ({' '}
                                {!isUpdating
                                    ? ''
                                    : completedPercentage != null
                                        ? `Updating... ${completedPercentage}%, `
                                        : 'Updating..., '}{' '}
                                LAST REFRESHED AT {renderRefreshDate(lastRefreshedDT)} )
                            </Typography>
                        </Box>
                    )}

                    <CngCrudTable
                        {...props}
                        fieldLevel=''
                        tableKey='nmotDashboardTable'
                        hasColumnsButton={true}
                        tableRef={(ref) => {
                            if (ref === null) {
                                return
                            }

                            tableRef.current = ref
                        }}
                        columns={columns}
                        del={{
                            url: NMoTApiUrls.NMOT_DELETE
                        }}
                        fetch={{
                            url: NMoTApiUrls.NMOT_DASHBOARD_GET
                        }}
                        fetchMode='FULL'
                        notification={notification}
                        options={{
                            filtering: true,
                            draggable: true,
                            selection: true,
                            showSelectAllCheckbox: false,
                            showTextRowsSelected: false,
                            // rowStyle: (rowData) => ({
                            //     backgroundColor:
                            //         selectedRowData.current &&
                            //             selectedRowData.current
                            //                 .map((row) => row.tableData.id)
                            //                 .includes(rowData.tableData.id)
                            //             ? '#e3effa'
                            //             : rowData.tableData.id % 2 == 0
                            //                 ? '#fafbfc'
                            //                 : '#ffffff'
                            // }),
                            tableLayout: 'fixed',
                        }}
                        localization={{
                            header: {
                                actions: 'Actions'
                            }
                        }}
                        deleteButtonProps={{
                            icon: ({ color, ...otherProps }) => (
                                <Trash {...otherProps} className={classes.trashIcon} />
                            ),
                            tooltip: translatedTextsObject.remove
                        }}
                        refreshButtonProps={{
                            hidden: true
                        }}
                        onSelectionChange={(rows) => {
                            selectedRowData.current = rows
                        }}
                        actions={[
                            {
                                icon: () => <Edit2 className={classes.editViewIcon} />,
                                tooltip: translatedTextsObject.edit,
                                onClick: (event, rowData) => editNMoT(rowData),
                                position: 'row'
                            },
                            {
                                icon: () => <Eye className={classes.editViewIcon} />,
                                tooltip: translatedTextsObject.view,
                                onClick: (event, rowData) => viewNMoT(rowData),
                                position: 'row'
                            },
                            {
                                icon: (props) => <Plus className={classes.addIcon} />,
                                tooltip: translatedTextsObject.add,
                                isFreeAction: true,
                                onClick: (event, rowData) => addNMoT(),
                                position: 'toolbar'
                            }
                        ]}
                        customToolbar={(toolbar) => {
                            const page = tableRefStateCallback()?.currentPage || 0
                            const pageSize = tableRefStateCallback()?.pageSize || 0
                            const totalResult = tableRefStateCallback()?.data?.length || 0

                            return (
                                <Box display='flex' alignItems='center'>
                                    <Box pl='8px' pt='8px'>
                                        <Typography variant='body2'>{`Showing ${Math.min(
                                            page * pageSize + 1,
                                            totalResult
                                        )}-${Math.min(
                                            (page + 1) * pageSize,
                                            totalResult
                                        )} of ${totalResult}`}</Typography>
                                    </Box>
                                    <Box flexDirection='row-reverse' flexGrow={1}>
                                        {toolbar}
                                    </Box>
                                </Box>
                            )
                        }}
                        postFetch={(data) => {
                            if (!firstRecordsCall) {
                                setFirstRecordsCall(true)
                                let dates = data.map((d) => new Date(d.refreshedDt))
                                let maxDate = new Date(Math.max.apply(null, dates))
                                setLastRefreshedDT(maxDate)
                            }
                            return data.map((d) => ({ ...d, id: d.id }))
                        }}
                        cngTableRef={cngTableRef}
                    />
                </Box>
            ) : (
                <CngBackdrop loading={loading} />
            )}
            <Box mt={2} mx={2}>
                <Grid container spacing={2} justify='center' >
                    <Box pr={2} pb={2} pt={1}>
                        <Button
                            variant='contained'
                            classes={{ root: 'ng-button-filled-primary' }}
                            style={{ width: '120px' }}
                            onClick={() => {
                                refreshTable(true)
                            }}
                            disabled={isUpdating}
                        >
                            {isUpdating
                                ? translatedTextsObject.updating
                                : translatedTextsObject.refresh}
                        </Button>
                    </Box>
                    <Box pr={2} pb={2} pt={1}>
                        <Button
                            variant='contained'
                            classes={{ root: 'ng-button-filled-primary' }}
                            onClick={() => {
                                setSelectedDialogOpen(true)
                            }}
                            disabled={isUpdating}
                        >
                            {translatedTextsObject.archiveDeleteSelected}
                        </Button>
                    </Box>
                    <Box pb={2} pt={1}>
                        <Button
                            variant='contained'
                            classes={{ root: 'ng-button-filled-primary' }}
                            onClick={() => {
                                setAllDialogOpen(true)
                            }}
                            disabled={isUpdating}
                        >
                            {translatedTextsObject.archiveDeleteDownloadAll}
                        </Button>
                    </Box>
                </Grid>
            </Box>

            <SelectedRowsDialog
                isDialogOpen={isSelectedDialogOpen}
                closeDialog={closeSelectedDialog}
                rowData={selectedRowData.current}
                setSelectedRowData={setSelectedRowData}
                showNotification={showNotification}
                cngTableRef={cngTableRef}
            />
            <AllRowsDialog
                isDialogOpen={isAllDialogOpen}
                closeDialog={closeAllDialog}
                showNotification={showNotification}
                cngTableRef={cngTableRef}
            />
            <CngDialog
                dialogContent={
                    <Box>
                        <Grid
                            container
                            spacing={1}
                            justify='center'
                            alignItems='center'
                        >
                            <Grid item xs={2} sm={2}>
                                <Box py={1} pl={3}>
                                    <FontAwesomeIcon
                                        icon={faExclamationCircle}
                                        color='#1769aa'
                                        style={{ fontSize: 30 }}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={10} sm={10}>
                                <Box>
                                    <Typography variant='body2' style={{ whiteSpace: 'wrap' }}>
                                        {translatedTextsObject.justRefreshed +
                                            `${waitTime} minutes(s)`}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container justify='center'>
                            <Box mt={1}>
                                <CngPrimaryButton onClick={closeFreqRefreshDialog}>
                                    Ok
                                </CngPrimaryButton>
                            </Box>
                        </Grid>
                    </Box>
                }
                dialogTitle={translatedTextsObject.note}
                open={isFreqRefreshDialogOpen}
                onClose={closeFreqRefreshDialog}
                shouldShowCloseButton
                maxWidth='sm'
            />
            <CngDialog
                dialogContent={
                    <Box>
                        <Grid
                            container
                            spacing={2}
                            justify='center'
                            alignItems='center'
                        >
                            <Grid item xs={2} sm={2}>
                                <Box py={1} pl={3}>
                                    <FontAwesomeIcon
                                        icon={faExclamationCircle}
                                        color='#aa2e25'
                                        style={{ fontSize: 30 }}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={10} sm={10}>
                                <Box ml={2}>
                                    <Typography variant='body2'>
                                        {translatedTextsObject.errorRefresh}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container justify='center'>
                            <Box mt={1}>
                                <CngPrimaryButton onClick={closeRefreshErrorDialog}>
                                    Ok
                                </CngPrimaryButton>
                            </Box>
                        </Grid>
                    </Box>
                }
                dialogTitle={translatedTextsObject.warning}
                open={isRefreshErrorDialogOpen}
                onClose={closeRefreshErrorDialog}
                shouldShowCloseButton
                maxWidth='sm'
            />
            <DisclaimerPromptDialog
                showNotification={showNotification}
            />
        </Paper>
    )
}

export default DashboardTable
